// extracted by mini-css-extract-plugin
export var root = "product-detail-module--root--CW4vk";
export var productSummary = "product-detail-module--productSummary--zjl35";
export var productThumbnail = "product-detail-module--productThumbnail--G5Yuf";
export var productShortInfor = "product-detail-module--productShortInfor--PTiYH";
export var productShortDescription = "product-detail-module--productShortDescription--pB+LE";
export var branding = "product-detail-module--branding--jMQr-";
export var productAvailable = "product-detail-module--productAvailable--9aRaX";
export var priceBox = "product-detail-module--priceBox--e-wkH";
export var productPrice = "product-detail-module--productPrice--qQstK";
export var comparePrice = "product-detail-module--comparePrice--WvHeH";
export var productSale = "product-detail-module--productSale--Ya0kr";
export var productDetailInfor = "product-detail-module--productDetailInfor--3yUJe";