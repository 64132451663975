import React from "react"
import { graphql } from "gatsby"
import { MasterLayoutScene } from "@bep43vn/scenes"
import { LocationRoute } from "@bep43vn/pages/page-routing"
import { SEO } from "@yolo-seo"
import * as styles from "./product-detail.module.scss"

interface ProductDetailPropsType {
  data: {
    markdownRemark: {
      html: string
      excerpt: string
      fields: {
        slug?: string
      }
      frontmatter: {
        title?: string
        price?: string
        comparePrice?: string
        productSale?: string
        imageThumbnail?: string
        collectionName?: string
        branding?: string
        shortDescription?: string
      }
    }
    site: {
      siteMetadata: {
        siteURL?: string
      }
    }
  }
}

export const pageQuery = graphql`
  query ProductDetailQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        price
        comparePrice
        productSale
        imageThumbnail
        collectionName
        branding
        shortDescription
      }
    }
    site {
      siteMetadata {
        siteURL
      }
    }
  }
`

export const ProductDetail: React.FCWithRoute<
  ProductDetailPropsType
> = props => {
  const htmlContent = props.data?.markdownRemark.html
  const {
    title,
    price,
    comparePrice,
    productSale,
    imageThumbnail,
    collectionName,
    branding,
    shortDescription,
  } = props.data?.markdownRemark.frontmatter || {}

  return (
    <MasterLayoutScene
      collectionName={collectionName}
      collectionHref={LocationRoute.HomePage}
    >
      <SEO
        title={title}
        description={shortDescription}
        imageTitle={
          (props.data?.site.siteMetadata.siteURL || "") + imageThumbnail
        }
        author="www.Bep43.vn"
        url={
          (props.data?.site.siteMetadata.siteURL || "") +
          props.data?.markdownRemark.fields.slug
        }
      ></SEO>
      <div className={styles.root}>
        <h1>{title}</h1>
        <div className={styles.productSummary}>
          <div className={styles.productThumbnail}>
            <img width={"100%"} src={imageThumbnail} alt={title}></img>
          </div>
          <div className={styles.productShortInfor}>
            <div className={styles.productShortDescription}>
              {shortDescription}
            </div>
            <div className={styles.branding}>Thương hiệu: {branding}</div>
            <div className={styles.productAvailable}>Tình trạng: Còn hàng</div>
            <div className={styles.priceBox}>
              <div className={styles.productPrice}>{price}</div>
              <div className={styles.comparePrice}>{comparePrice}</div>
              <div className={styles.productSale}>{productSale}</div>
            </div>
          </div>
        </div>
        <div
          className={styles.productDetailInfor}
          dangerouslySetInnerHTML={{
            __html: htmlContent || "",
          }}
        ></div>
      </div>
    </MasterLayoutScene>
  )
}

export default ProductDetail
